const heroNew = document.querySelectorAll('.homepage-header');

heroNew.forEach(hero => {
    const $this = hero;
    const heroImg = $this.querySelector('.homepage-header__image img');
    const heroImgSrc = heroImg.getAttribute('src');
        
    heroImg.setAttribute('src', '');
    heroImg.setAttribute('src', heroImgSrc);

    heroImg.addEventListener('load', () => {
        setTimeout(() => {
            $this.classList.add('js-homepage-header--ready');
        }, 200);
    });
});